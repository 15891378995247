<template>
  <NuxtLayout>
    <div
      id="pixel2"
      data-pid="18917"
      data-uid="c2cc7947-8e04-4f8b-b3ba-4321d9a713af"
    />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
const { $headsMeta } = useNuxtApp();
const pagesStore = usePagesStore();
const route = useRoute();

const { refresh: refreshHeadsData } = await useAsyncData(
  'pages',
  async () => {
    await pagesStore.loadPageData(route.path);
    updateMeta();
    return { success: true };
  },
  {
    server: false,
    lazy: true,
  },
);

const updateMeta = () => {
  useHead({
    title: pagesStore.currentPage?.title
      ? `${pagesStore.currentPage.title} - Простой вопрос`
      : 'Простой вопрос',

    meta: [
      ...$headsMeta(pagesStore.currentPage),
      { name: 'yandex-verification', content: '5276870b7036014b' },
    ],
  });
};

await useAsyncData(
  'page',
  async () => {
    await pagesStore.getPageSpoilers();
  },
  {
    server: false,
    lazy: true,
  },
);

watch(
  () => route.path,
  async () => {
    await refreshHeadsData();
    updateMeta();
  },
);
</script>
