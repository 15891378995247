import { v4 as uuidv4 } from 'uuid';

export default defineNuxtRouteMiddleware((to, from) => {
  const emailIdCookie = useCookie('email_id');
  if (!emailIdCookie.value) {
    emailIdCookie.value = Math.round(Math.random() * (3 - 1) + 1);
    emailIdCookie.maxAge = 60 * 60 * 24;
  }

  const telemetrySessionId = useCookie('tm_session_id');
  if (!telemetrySessionId.value) {
    telemetrySessionId.value = uuidv4();
    telemetrySessionId.maxAge = 60 * 60 * 6;
  }
});
