import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('telemetryLog', async (data) => {
    try {
      if (!data || !data.event_name) return;

      const { $api } = useNuxtApp();

      await $api.lkServer().sendTelemetryEvent({
        event_name: data.event_name,
        session_id: useCookie('tm_session_id').value,
        partner: useCookie('pageqs').value?.from2,
        wm_id: useCookie('pageqs').value?.wm_id,
      });
    } catch {
      //
    }
  });
});
