export default (o, instance) => {
  return {
    helpCity(payload) {
      return instance(o).post('/suggest/address', payload);
    },
    helpFullName(payload) {
      return instance(o).post('/suggest/fio', payload);
    },
    helpPassportCode(payload) {
      return instance(o).post('/suggest/fms_unit', payload);
    },
  };
};
