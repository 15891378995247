export default defineNuxtPlugin(() => {
  return {
    provide: {
      headsMeta(data) {
        return [
          {
            hid: 'description',
            name: 'description',
            content: data.metaDescription || '',
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: data.metaKeywords || '',
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: data.ogUrl || '',
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: data.ogType || '',
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: data.ogTitle || '',
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: data.ogDescription || '',
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content:
              data.ogImage ||
              'https://api.prostoyvopros.ru/v1/file/1701080001202_androidchrome512x512.png',
          },
          {
            hid: 'og:image:width',
            property: 'og:image:width',
            content: data.ogImageWidth || '1200',
          },
          {
            hid: 'og:image:height',
            property: 'og:image:height',
            content: data.ogImageHeight || '630',
          },
          {
            hid: 'twitter:card',
            property: 'twitter:card',
            content: data.twitterCard || '',
          },
          {
            hid: 'twitter:domain',
            property: 'twitter:domain',
            content: data.twitterDomain || '',
          },
          {
            hid: 'twitter:title',
            property: 'twitter:title',
            content: data.twitterTitle || '',
          },
          {
            hid: 'twitter:description',
            property: 'twitter:description',
            content: data.twitterDescription || '',
          },
          {
            hid: 'twitter:image',
            property: 'twitter:image',
            content:
              data.twitterImage ||
              'https://api.prostoyvopros.ru/v1/file/1701080001202_androidchrome512x512.png',
          },
          {
            hid: 'twitter:card',
            property: 'twitter:card',
            content: data.twitterCard || 'summary_large_image',
          },
          {
            hid: 'og:locale',
            property: 'og:locale',
            content: data.ogLocale || '',
          },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: data.ogSiteName || '',
          },
        ];
      },
    },
  };
});
