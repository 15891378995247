<template>
  <div class="flex items-center justify-center">
    <span
      class="loading loading-spinner pointer-events-none select-none"
      :class="[loadingSize, white ? 'text-white' : 'dark:text-mainYellow text-black']"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  white: Boolean,
  size: {
    type: String,
    default: 'lg',
    validator: (size) => ['xs', 'sm', 'md', 'lg'].includes(size),
  },
});

const sizes = {
  xs: 'loading-xs',
  sm: 'loading-sm',
  md: 'loading-md',
  lg: 'loading-lg',
};

const loadingSize = computed(() => sizes[props.size]);
</script>
