export const usePagesStore = defineStore('pages', () => {
  const currentPage = ref({});
  const pageSpoilersBySumm = ref([]);
  const pageSpoilersOffers = ref([]);
  const pageSpoilersbyGeo = ref([]);

  const getCurrentPage = computed(() => currentPage.value);

  const { $api } = useNuxtApp();

  const getPageName = (pageName) => {
    if (pageName.includes('tran_id=') || pageName.includes('sposobyi-oplatyi-document')) {
      return 'sposobyi-oplatyi';
    }

    const pathSegments = pageName.split('/').filter(Boolean);
    const path = pathSegments.length > 0 ? pathSegments.pop() : 'index';
    return path === '' ? 'index' : path;
  };

  const getPageData = async (pageName) => {
    try {
      const { data } = await $api.web().getPageData(pageName);
      currentPage.value = data.data;
      return currentPage.value;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const loadPageData = async (pageName) => {
    if (pageName.includes('/undefined/')) return;
    if (currentPage.value && currentPage.value.name === getPageName(pageName)) {
      return currentPage.value;
    }
    return await getPageData(getPageName(pageName));
  };

  const getPageSpoilers = async () => {
    try {
      const { data } = await $api.web().getPageSpoilers();
      pageSpoilersBySumm.value = data.data.bySumm;
      pageSpoilersOffers.value = data.data.offers;
      pageSpoilersbyGeo.value = data.data.byGeo;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    currentPage,
    getCurrentPage,
    getPageName,
    pageSpoilersBySumm,
    pageSpoilersOffers,
    pageSpoilersbyGeo,
    getPageSpoilers,
    getPageData,
    loadPageData,
  };
});
