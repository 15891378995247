import { default as _91name_93JPQBxqE1SEMeta } from "/app/pages/[name].vue?macro=true";
import { default as appealVSheL8HZRZMeta } from "/app/pages/appeal.vue?macro=true";
import { default as index0oJfcwwdeYMeta } from "/app/pages/blog/[category]/[name]/index.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as indexXU9e09BrvjMeta } from "/app/pages/blog/topic/[name]/index.vue?macro=true";
import { default as complaintUjrwxGzYGgMeta } from "/app/pages/complaint.vue?macro=true";
import { default as documentsAtnLAchromMeta } from "/app/pages/documents.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_91document_93_93QcttvW1ZiEMeta } from "/app/pages/sposobyi-oplatyi/[[document]].vue?macro=true";
import { default as thankyoulkJCO3CQLG8MMeta } from "/app/pages/thankyoulk.vue?macro=true";
import { default as vidy_45zajmovQIVoJgF4FIMeta } from "/app/pages/vidy-zajmov.vue?macro=true";
import { default as vzyat_45v_45dolgdPQWQPgphKMeta } from "/app/pages/vzyat-v-dolg.vue?macro=true";
import { default as zajmy_45po_45gorodamSMW5kHqL4cMeta } from "/app/pages/zajmy-po-gorodam.vue?macro=true";
import { default as zajmy_45po_45summamAtSGeDJaSKMeta } from "/app/pages/zajmy-po-summam.vue?macro=true";
export default [
  {
    name: "name",
    path: "/:name()",
    component: () => import("/app/pages/[name].vue").then(m => m.default || m)
  },
  {
    name: "appeal",
    path: "/appeal",
    component: () => import("/app/pages/appeal.vue").then(m => m.default || m)
  },
  {
    name: "blog-category-name",
    path: "/blog/:category()/:name()",
    meta: index0oJfcwwdeYMeta || {},
    component: () => import("/app/pages/blog/[category]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-topic-name",
    path: "/blog/topic/:name()",
    component: () => import("/app/pages/blog/topic/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: "complaint",
    path: "/complaint",
    component: () => import("/app/pages/complaint.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: documentsAtnLAchromMeta || {},
    component: () => import("/app/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "sposobyi-oplatyi-document",
    path: "/sposobyi-oplatyi/:document?",
    component: () => import("/app/pages/sposobyi-oplatyi/[[document]].vue").then(m => m.default || m)
  },
  {
    name: "thankyoulk",
    path: "/thankyoulk",
    component: () => import("/app/pages/thankyoulk.vue").then(m => m.default || m)
  },
  {
    name: "vidy-zajmov",
    path: "/vidy-zajmov",
    meta: vidy_45zajmovQIVoJgF4FIMeta || {},
    component: () => import("/app/pages/vidy-zajmov.vue").then(m => m.default || m)
  },
  {
    name: "vzyat-v-dolg",
    path: "/vzyat-v-dolg",
    component: () => import("/app/pages/vzyat-v-dolg.vue").then(m => m.default || m)
  },
  {
    name: "zajmy-po-gorodam",
    path: "/zajmy-po-gorodam",
    meta: zajmy_45po_45gorodamSMW5kHqL4cMeta || {},
    component: () => import("/app/pages/zajmy-po-gorodam.vue").then(m => m.default || m)
  },
  {
    name: "zajmy-po-summam",
    path: "/zajmy-po-summam",
    meta: zajmy_45po_45summamAtSGeDJaSKMeta || {},
    component: () => import("/app/pages/zajmy-po-summam.vue").then(m => m.default || m)
  }
]