import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/app/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9MY4eAfYMX from "/app/node_modules/@artmizu/yandex-metrika-nuxt/dist/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import index_VHbHwe8vN1 from "/app/plugins/api/index.js";
import router_19BuzgFWKZ from "/app/plugins/router.js";
import _01_sentry_client_WDNVdfLkAG from "/app/plugins/01.sentry.client.js";
import dayjs_FoMn5Zigri from "/app/plugins/dayjs.js";
import errorHandler_cfkZ75xwSQ from "/app/plugins/errorHandler.js";
import fingerprint_client_Z9SxJPXU9n from "/app/plugins/fingerprint.client.js";
import headsMeta_oSMtfFHsX7 from "/app/plugins/headsMeta.js";
import maska_r8BHBbSmHt from "/app/plugins/maska.js";
import socket_client_N9cHubToyQ from "/app/plugins/socket.client.js";
import telemetryLog_mtpbUpoP91 from "/app/plugins/telemetryLog.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_9MY4eAfYMX,
  plugin_AUP22rrBAc,
  index_VHbHwe8vN1,
  router_19BuzgFWKZ,
  _01_sentry_client_WDNVdfLkAG,
  dayjs_FoMn5Zigri,
  errorHandler_cfkZ75xwSQ,
  fingerprint_client_Z9SxJPXU9n,
  headsMeta_oSMtfFHsX7,
  maska_r8BHBbSmHt,
  socket_client_N9cHubToyQ,
  telemetryLog_mtpbUpoP91
]