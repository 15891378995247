export default (o, instance) => {
  return {
    getMessages({ room_id, client_id }) {
      return instance(o).get('/messages/client', {
        params: {
          room_id,
          client_id,
        },
      });
    },
    addAppeal(payload) {
      return instance(o).post('/appeals/client', payload);
    },
    getOperatorsAvailability() {
      return instance(o).get(`/worktime/available`);
    },
  };
};
