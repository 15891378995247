import { defineStore } from 'pinia';
import { useTipTapStore } from './tiptap';
import { useChatStore } from './chat';
import { useNotieStore } from '~/stores/notifications';

export const useChatFilesStore = defineStore(
  'chatFiles',
  () => {
    const { $api } = useNuxtApp();

    const notieStore = useNotieStore();
    const tipTapStore = useTipTapStore();
    const chatStore = useChatStore();

    const progress = ref(0);
    const files = ref([]);
    const refInput = ref(null);
    const filesSign = ref('');

    const addFilesToUpload = (filesToUpload) => {
      filesToUpload = Array.isArray(filesToUpload) ? filesToUpload : [filesToUpload];

      const generalCountFiles = filesToUpload.length + files.value.length;
      if (generalCountFiles > 6) {
        notieStore.addNotification({
          type: 'warning',
          message: 'Вы не можете загрузить больше 6 файлов за один раз',
        });
        refInput.value.value = '';
        return;
      }

      const filteredFiles = filesToUpload.filter((i) => i.size / 1024 / 1024 <= 5);
      if (filteredFiles.length < filesToUpload.length) {
        notieStore.addNotification({
          type: 'warning',
          message: 'Размер файлов не должен превышать 5 Мбайт',
        });
        refInput.value.value = '';
        return;
      }

      for (const file of filesToUpload) {
        const dublicateFile = files.value.find((item) => item.name === file.name);
        if (dublicateFile) {
          notieStore.addNotification({
            type: 'warning',
            message: `Файл ${file.name} уже добавлен!`,
          });
          refInput.value.value = '';
          return;
        }
      }

      files.value = files.value.concat([...filesToUpload]);

      setTimeout(() => {
        tipTapStore.editor.commands.focus();
      }, 0);
      refInput.value.value = '';
    };

    const uploadFiles = async (files) => {
      files = Array.isArray(files) ? files : [files];
      const formData = new FormData();

      for (const file of files) {
        formData.append('multi-files', file);
      }
      const uploadedFiles = await $api.diskChat().uploadFiles({
        files: formData,
        client_id: chatStore.clientId,
        room_id: chatStore.roomId,
        brand_id: '6551eee4de278d0b8d0c6e01',
      });

      return uploadedFiles.data.data;
    };

    const deleteFile = (name) => {
      files.value = files.value.filter((item) => item.name !== name);
      refInput.value.value = '';
    };

    const compactFileName = (name) => {
      return name.slice(0, 10) + '.' + name.split('.').pop();
    };

    const extensionsFile = (name) => {
      return '.' + name.toLowerCase().split('.').pop();
    };

    return {
      refInput,
      files,
      progress,
      filesSign,
      addFilesToUpload,
      uploadFiles,
      compactFileName,
      extensionsFile,
      deleteFile,
    };
  },
  {
    persist: false,
  },
);
