export default (o, instance) => {
  return {
    getPaymentUrl(data) {
      return instance(o).post('/payment/contract/pay', data);
    },

    getPaymentContract(data) {
      return instance(o).get('/payment/contract', { params: data });
    },
  };
};
