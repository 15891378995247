export default defineNuxtRouteMiddleware(async (to, from) => {
  const { error } = useNuxtApp();
  const pagesStore = usePagesStore();

  if (to.path.startsWith('/so/')) {
    const pathParts = to.path.split('/');
    if (pathParts.length > 2 && pathParts[2]) {
      return navigateTo(`/sposobyi-oplatyi/${pathParts[2]}`, { redirectCode: 301 });
    }
  }

  if (to.path.startsWith('/pl/') || to.path.startsWith('/pl')) {
    const pathParts = to.path.split('/');
    if (pathParts.length > 2 && pathParts[2]) {
      return navigateTo(`/sposobyi-oplatyi/${pathParts[2]}`, { redirectCode: 301 });
    } else {
      return navigateTo(`/sposobyi-oplatyi/?tran_id=${to.query.tr_id}/`, { redirectCode: 301 });
    }
  }

  switch (to.path) {
    case String(to.path.match(/\/blog\/topic\/[^/]+\/page\/1\//)):
      return navigateTo(`/blog/topic/${to.params.name}/`, { redirectCode: 301 });
    default:
      break;
  }

  if (to.name === 'name') {
    try {
      await pagesStore.loadPageData(to.path);
    } catch (err) {
      error({ message: JSON.stringify(err.message), statusCode: err.response.status });
    }
  }
});
