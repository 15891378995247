export default (o, instance) => {
  return {
    uploadFiles({ files, client_id, room_id, brand_id }) {
      return instance(o).post('/files/client', files, {
        params: {
          client_id,
          room_id,
          brand_id,
        },
      });
    },
    uploadFilesForAppeal({ files, client_id, room_id, brand_id }) {
      return instance(o).post('/files/appeals', files, {
        params: {
          client_id,
          room_id,
          brand_id,
        },
      });
    },
  };
};
