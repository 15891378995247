import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v3';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  nuxtApp.vueApp.use(fpjsPlugin, {
    loadOptions: {
      apiKey: config.public.fingerPrintPubKey,
    },
  });
});
