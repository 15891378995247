import { useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const pixel = useScript({"key":"pixel","src":"https://pixel.scoring.ru/pixel.js","async":true}, { use: () => ({ pixel: window.pixel }) })
    return { provide: { $scripts: { pixel } } }
  }
})