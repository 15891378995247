import { defineStore } from 'pinia';

export const useChatStore = defineStore(
  'chat',
  () => {
    const isChatOpened = ref(false);
    const roomId = ref('');
    const clientId = ref('');
    const appealId = ref('');
    const wssToken = ref('');
    const messages = ref([]);
    const isOperatorTyping = ref(false);
    const _operatorTypingTimeout = ref(null);
    const isChatAvailable = ref(false);

    const nuxtApp = useNuxtApp();
    const route = useRoute();

    const chatFilesStore = useChatFilesStore();

    const unreadMessages = computed(() =>
      messages.value.filter((item) => item.admin_name && item.unread),
    );

    const connectToRoom = () => {
      nuxtApp.$ws.sockets.chat.emit('room:join', null, async (res) => {
        if (res.status === 'ok') {
          // Сохранение roomId и clientId в куку
          roomId.value = res.room_id;
          clientId.value = res.client_id;

          if (route.name !== 'chat') {
            await getChatMessages();
          }
        }
      });
    };

    const setOperatorTyping = (isTyping = true) => {
      isOperatorTyping.value = isTyping;
      if (_operatorTypingTimeout.value) {
        clearTimeout(_operatorTypingTimeout.value);
        _operatorTypingTimeout.value = null;
      }

      if (!isTyping) return;

      _operatorTypingTimeout.value = setTimeout(() => {
        isOperatorTyping.value = false;
        _operatorTypingTimeout.value = null;
      }, 5000);
    };

    const getChatMessages = async () => {
      try {
        const msgRes = await nuxtApp.$api
          .chat()
          .getMessages({ room_id: roomId.value, client_id: clientId.value });

        messages.value = msgRes.data.data.messages;
        chatFilesStore.filesSign = msgRes.data.data.sign;
      } catch (err) {
        console.log(err);
      }
    };

    const hasNonBotUnreadMessages = computed(() => {
      return unreadMessages.value.some((m) => m.admin_name !== 'Бот');
    });

    const readOperatorMessages = () => {
      nuxtApp.$ws.sockets.chat.emit('message:read', roomId.value);
      unreadMessages.value.forEach((m) => (m.unread = false));
    };

    const readMyMessages = () => {
      messages.value.forEach((m) => {
        m.unread = false;
      });
    };

    const $reset = () => {
      roomId.value = '';
      clientId.value = '';
      wssToken.value = '';
      messages.value = [];
    };

    return {
      unreadMessages,
      roomId,
      clientId,
      isChatOpened,
      appealId,
      wssToken,
      messages,
      isOperatorTyping,
      isChatAvailable,
      getChatMessages,
      readOperatorMessages,
      readMyMessages,
      hasNonBotUnreadMessages,

      connectToRoom,
      setOperatorTyping,
      $reset,
    };
  },
  {
    persist: {
      paths: ['roomId', 'appealId', 'wssToken', 'clientId'],
    },
  },
);
