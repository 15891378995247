import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45trailing_45slash_45redirect_45global from "/app/middleware/01.trailingSlashRedirect.global.js";
import extra_45settings_45global from "/app/middleware/extraSettings.global.js";
import redirects_45global from "/app/middleware/redirects.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45trailing_45slash_45redirect_45global,
  extra_45settings_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}