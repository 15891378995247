<template>
  <i
    ref="iconRef"
    class="pointer-events-none flex items-center self-center"
    v-html="icon"
  />
</template>

<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    required: false,
    default: 'sm',
    validator: (size) => ['', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(size),
  },
  fill: {
    type: String,
    required: false,
    default: undefined,
  },
  fillBg: {
    type: String,
    required: false,
    default: undefined,
  },
  stroke: {
    type: [Array, String],
    required: false,
    default: undefined,
  },
  strokeWidth: {
    type: String,
    required: false,
    default: '',
  },
});

const icon = ref('');
const iconRef = ref(null);

const iconSize = computed(() => {
  const sizes = {
    xs: '12',
    sm: '18',
    md: '24',
    lg: '32',
    xl: '44',
    xxl: '70',
  };
  return sizes[props.size] || props.size || '';
});

const file = `${props.name}`;

const rootModules = import.meta.glob('/assets/icons/*.svg', {
  query: '?raw',
  import: 'default',
  eager: true,
});

const modules = import.meta.glob('../assets/icons/*.svg', {
  query: '?raw',
  import: 'default',
  eager: true,
});

const importedIcon = computed(() => {
  if (rootModules['/assets/icons/' + file + '.svg']) {
    return rootModules['/assets/icons/' + file + '.svg'];
  } else {
    return modules['../assets/icons/' + file + '.svg'] ?? modules['../assets/icons/broken.svg'];
  }
});

// Функция изменения аттрибутов иконки
const geticon = computed(() => {
  let res = importedIcon.value || '';
  if (props.fill) {
    if (res.match(/path/)) {
      res = res.replace(/path/g, `path fill="${props.fill}"`);
    } else if (res.match(/<g/)) {
      res = res.replace(/<g/g, `<g fill="${props.fill}"`);
    }
  }
  if (props.stroke) {
    if (res.match(/path/)) {
      res = res.replace(/path/g, `path stroke="${props.stroke}"`);
    } else if (res.match(/<g/)) {
      res = res.replace(/<g/g, `<g stroke="${props.stroke}"`);
    }
  }

  if (props.strokeWidth) {
    if (res.match(/stroke-width/)) {
      res = res.replace(/stroke-width="(\d+)"/g, `stroke-width="${props.strokeWidth}"`);
    } else {
      res = res.replace(/path/g, `path stroke-width="${props.strokeWidth}"`);
    }
  }

  if (props.fillBg) {
    if (res.match(/rect/)) {
      res = res.replace(/rect/g, `rect fill="${props.fillBg}"`);
    }
  }

  if (iconSize.value) {
    res = res.replace(/svg/, `svg width="${iconSize.value}" height="${iconSize.value}"`);
    res = res
      .replace(/width="([\d]+)"/, `width="${iconSize.value}"`)
      .replace(/height="([\d]+)"/, `height="${iconSize.value}"`);
  }

  return res;
});

// Применение функции изменения аттрибутов при загрузке
onServerPrefetch(() => {
  // if (props.customSize) {
  //   await nextTick();
  //   setDimensions();
  //   iconRef.value.addEventListener('resize', setDimensions);
  // }
  icon.value = geticon.value;
});

onMounted(() => (icon.value = geticon.value));

// const setDimensions = async () => {
//   await nextTick();
//   const { width } = iconRef.value.getBoundingClientRect();
//   console.log({ width });
//   width.value = width;
// };

watch(
  () => props,
  () => {
    icon.value = geticon.value;
  },
);
</script>

<script>
export default {
  name: 'Icon',
};
</script>
