<template>
  <button
    class="btn relative !flex gap-2 !border-transparent shadow-none"
    :class="{
      [customClasses]: customClasses,
      'text-4 h-16 w-full rounded-lg lg:h-18 lg:rounded-2.5xl lg:text-lg':
        !small && !square && !customClasses,
      small: small,
      square: square,
      error: error,
      primary: type === 'primary' && !customClasses,
      secondary: type === 'secondary',
      tertiary: type === 'tertiary',
      white: type === 'white',
      'btn-disabled': disabled,
      'btn-loading': isLocalLoading || loading,
    }"
    :disabled="isLocalLoading || loading || disabled"
    type="button"
    @click="handleClick"
  >
    <span
      v-if="notification"
      class="absolute -right-1 -top-1 flex size-3.5 items-center justify-center rounded-full bg-ui_red text-xxxs text-white lg:size-5 lg:h-5 lg:w-5 lg:text-xs"
    >
      <Icon name="chatNotification" />
    </span>

    <IsLoading
      v-if="isLocalLoading || loading"
      :size="small ? 'md' : 'lg'"
    />

    <slot v-if="$slots.default && !isLocalLoading && !loading"></slot>
  </button>
</template>

<script setup>
import Icon from '~/components/Ui/Icon.vue';
import IsLoading from '~/components/Ui/IsLoading.vue';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  fakeLoading: {
    type: [Number, String],
    default: 0,
  },
  customClasses: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  square: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  notification: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'primary',
  },
});

const isLocalLoading = ref(false);

const handleClick = () => {
  if (props.fakeLoading) {
    isLocalLoading.value = true;
    setTimeout(() => {
      isLocalLoading.value = false;
    }, props.fakeLoading);
  }
};
</script>

<style scoped>
.small {
  @apply h-10 min-h-[38px] rounded-lg text-xs lg:h-16 lg:rounded-2xl lg:text-base;
}

.square {
  @apply !size-10 min-h-10 rounded-lg lg:size-16 lg:min-h-16 lg:rounded-2xl;
}

.error {
  @apply border !border-ui_red !bg-transparent text-ui_red hover:border-ui_red hover:bg-ui_light_grey;
}

.primary {
  @apply border-ui_yellow bg-ui_yellow hover:border-ui_light_yellow hover:bg-ui_yellow disabled:bg-ui_light_grey lg:hover:bg-ui_mid_yellow;
}

.secondary {
  @apply bg-ui_light_grey hover:border-ui_mid_grey disabled:bg-ui_light_grey lg:hover:bg-ui_mid_grey;
}

.tertiary {
  @apply border-ui_white bg-ui_white disabled:bg-ui_light_grey lg:hover:bg-ui_light_yellow;
}

.white {
  @apply border-ui_white bg-ui_white hover:border-ui_white hover:bg-ui_white;
}

.btn-disabled {
  @apply cursor-not-allowed border-none;
}

.btn-loading {
  @apply cursor-wait border-none;
}
</style>
