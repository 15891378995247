import sha256 from 'sha256';

export default (o, instance) => {
  return {
    // PAYMENTS
    getPaymentMethods() {
      return instance(o).get('/paymentMethods');
    },
    getPaymentContract(payload) {
      return instance(o).get('/payment/contract', {
        params: {
          payment_type: payload.payment_type,
          account_number: payload.account_number,
          birth_date: payload.birth_date,
        },
      });
    },

    payTheContract(payload) {
      return instance(o).post('/payment/contract/pay', payload);
    },
    // REVIEWS
    getAllReviews() {
      return instance(o).get('/reviews');
    },
    addLoanApplication(payload) {
      return instance(o).post('/applications/loan', payload);
    },
    // BLOG
    getPublishedBlogPosts(data) {
      return instance(o).get(`/blog/page/${data.page}`, {
        params: {
          filter: data.filter,
          categoryId: data.categoryId,
        },
      });
    },
    getOnePublishedBlogPost({ name, category }) {
      return instance(o).get(`/blog/${category}/${name}`);
    },
    getBlogCategories() {
      return instance(o).get('/blog/categories');
    },
    // SETTINGS
    getOnePublicSetting(setting) {
      return instance(o).get(`/settings/${setting}`);
    },
    tempRegistrationRequired(kladr_id) {
      return instance(o).get('/info/tempRegistrationRequired', {
        params: {
          kladr_id,
        },
      });
    },
    getTranInfo(payload) {
      return instance(o).get('/payment/traninfo', {
        params: {
          tran_id: payload,
        },
      });
    },
    // DOCUMENTS
    getAllDocuments() {
      return instance(o).get('/documents');
    },
    getPageData(name) {
      return instance(o).get(`/pages/page/${name}`);
    },
    getPageSpoilers() {
      return instance(o).get('/pages/spoilers');
    },

    smsSend({ phone, tmSessionId, visitor_id, qs }) {
      const x = 'const x = 1;';
      const site = sha256(`${phone}${x}`);
      return instance(o).post('/sms/verify', {
        phone,
        site,
        tm_session_id: tmSessionId,
        visitor_id,
        qs,
      });
    },
    smsVerify({ phone, code, tmSessionId, qs }) {
      return instance(o).get(`/sms/verify/${phone}/${code}`, {
        params: {
          tm_session_id: tmSessionId,
          qs,
        },
      });
    },
  };
};
