import { defineStore } from 'pinia';

export const useTipTapStore = defineStore(
  'tiptap',
  () => {
    const editor = ref({});

    return {
      editor,
    };
  },
  {
    persist: false,
  },
);
