import { defineStore } from 'pinia';

export const useNotieStore = defineStore('notie', (settings = {}) => {
  const noties = ref([]);

  const addNotification = (notification) => {
    if (noties.value.length < (settings.maxCount || 3)) {
      notification._id = Math.random().toString(36).slice(2);
      notification.timestamp = Date.now();

      if (!notification.id) {
        notification.id = Math.random().toString(36).slice(2);
      }

      noties.value.push(notification);

      if (notification.duration === -1 || notification.sticky) return;

      setTimeout(
        () => {
          deleteNotification(notification);
        },
        notification.duration || settings.lifetime || 3000,
      );
    }
  };

  const deleteNotification = (n) => {
    if (typeof n.closable === 'undefined' || n.closable === true) {
      noties.value = noties.value.filter((i) => i.id !== n.id);
    }
  };

  const add = (n) => {
    return addNotification(n);
  };

  const remove = (n) => {
    return deleteNotification(n);
  };

  const removeBy = (aggregations) => {
    const operators = ['$eq', '$ne', '$gt', '$gte', '$lt', '$lte'];
    let _noties = [...noties.value];

    for (const { operator, key, value } of aggregations) {
      if (!operators.includes(operator)) continue;

      if (operator === '$eq') {
        _noties = _noties.filter((n) => n[key] === value);
      }
      if (operator === '$ne') {
        _noties = _noties.filter((n) => n[key] !== value);
      }
      if (operator === '$gt') {
        _noties = _noties.filter((n) => n[key] > value);
      }
      if (operator === '$gte') {
        _noties = _noties.filter((n) => n[key] >= value);
      }
      if (operator === '$lt') {
        _noties = _noties.filter((n) => n[key] < value);
      }
      if (operator === '$lte') {
        _noties = _noties.filter((n) => n[key] <= value);
      }
    }

    const ids = _noties.map((x) => x._id);

    noties.value = noties.value.filter((n) => !ids.includes(n._id));
    return noties;
  };

  const forcePatch = (payload) => {
    const index = noties.value.findIndex((n) => n.id === payload.id);
    if (index === -1) {
      addNotification(payload);
      return;
    }
    Object.assign(noties.value[index], payload);
  };

  const clear = () => {
    noties.value = [];
  };

  const $reset = () => {
    noties.value = [];
  };

  return {
    noties,
    addNotification,
    deleteNotification,
    add,
    remove,
    forcePatch,
    clear,
    $reset,
    removeBy,
  };
});
