<template>
  <NuxtLayout>
    <section
      v-if="!isLoading"
      class="flex w-full flex-col items-center justify-between gap-[32px] lg:h-full"
    >
      <h1>Страница не найдена</h1>
      <div class="flex w-full flex-col items-center justify-center gap-[32px] lg:gap-[40px]">
        <img
          class="pointer-events-none w-4/5 max-w-[40px] select-none"
          src="~/assets/icons/error.svg "
          alt="Image not found"
        />
        <div
          class="flex w-4/5 flex-col items-center justify-between gap-[18px] pb-[16px] text-center lg:w-1/2 lg:items-stretch lg:gap-[32px] lg:text-left"
        >
          <p class="text-center text-lg text-dnd_dark_grey lg:text-xl 2xl:text-2xl">
            Похоже что-то пошло не так
          </p>
          <div class="flex w-fit min-w-[150px] self-center">
            <UiButton @click="navigateTo('/')">Перейти на главную страницу</UiButton>
          </div>
        </div>
      </div>
    </section>
    <UiIsLoading v-else />
  </NuxtLayout>
</template>

<script setup>
defineProps({
  error: {
    type: Object,
    default: null,
  },
});

const isLoading = ref(true);

onMounted(() => (isLoading.value = false));
</script>

<style></style>
