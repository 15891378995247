export default defineNuxtPlugin({
  name: 'Sentry',
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.hook('app:mounted', async () => {
      if (import.meta.client) {
        const [Sentry, { initSentry }] = await Promise.all([
          import('@sentry/vue'),
          import('../sentryInit'),
        ]);
        initSentry(Sentry, nuxtApp.vueApp, useRouter(), nuxtApp);
      }
    });
  },
});
