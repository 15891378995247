import Instances from './instances';
import web from './web';
import geo from './geo';
import lkServer from './lkServer';
import diskChat from './diskChat';
import chat from './chat';

import payment from './payment';
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  return {
    provide: {
      api: {
        web: () => web(config, Instances.web),
        lkServer: () => lkServer(config, Instances.lkServer),
        geo: () => geo(config, Instances.geo),

        diskChat: () => diskChat(config, Instances.diskChat),
        chat: () => chat(config, Instances.chat),
        payment: () => payment(config, Instances.payment)
      },
    },
  };
});
