export default (o, instance) => {
  return {
    getProductMatrix() {
      return instance(o).get('/info/productMatrix');
    },
    saveClick(params) {
      return instance(o).post('/long/click', {}, { params });
    },
    saveLongData(data) {
      return instance(o).post('/long/saveLongData', data);
    },
    verifyLead(payload) {
      return instance(o).post(`/long/redirect`, payload);
    },
    sendTelemetryEvent(data) {
      return instance(o).post('/info/telemetry', data);
    },
  };
};
