import axios from 'axios';

export default class Instances {
  static web(c) {
    const baseURL =
      c.public.appEnv === 'production' && import.meta.server
        ? c.public.baseURLLocal
        : c.public.baseURL;
    const instance = axios.create({
      baseURL,
      withCredentials: true,
    });

    return instance;
  }
  static lkServer(c) {
    return axios.create({
      baseURL: c.public.lkServerUrl,
    });
  }
  static geo(c) {
    return axios.create({
      baseURL: c.public.dadataURL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + c.public.dadataToken,
      },
    });
  }
  static diskChat(c) {
    const instance = axios.create({
      baseURL: c.public.chatServerUrl,
      withCredentials: true,

      headers: {
        'Content-Type': 'multipart/formdata',
        Authorization: 'Bearer ' + c.public.chatServerToken,
      },
    });

    return instance;
  }

  static chat(c) {
    const instance = axios.create({
      baseURL: c.public.chatServerUrl,
      withCredentials: true,

      headers: {
        Authorization: 'Bearer ' + c.public.chatServerToken,
      },
    });

    return instance;
  }

  static payment(c) {
    return axios.create({
      baseURL: c.public.paymentServerUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}
