export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client) return;

  if (to && to.path) {
    if (to.path.endsWith('//') && to.path.replace(/\/+$/, '') === '') {
      return navigateTo({ path: '/' }, { redirectCode: 301 });
    }

    if ((to.path !== '/' && to.path.endsWith('//')) || !to.path.endsWith('/')) {
      const { path, query, hash } = to;
      const nextPath = path.replace(/\/+/g, '/').replace(/\/$/, '');

      const nextRoute = { path: nextPath + '/', query, hash };
      if (nextRoute !== to.path) {
        return navigateTo(nextRoute, { redirectCode: 301 });
      }
    }
  }

  return null;
});
